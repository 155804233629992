import React from "react";
import panditji from "../assets/panditji2.jfif";
import { BiPhoneCall } from "react-icons/bi";
const About = () => {
  return (
    <div className="about  ">
 
      <div className="about-img">

      <img  src={panditji} alt="" />
      {/* <img src='https://cdni.iconscout.com/illustration/premium/thumb/pandit-ji-reading-mantras-2775577-2319299.png' alt={'pandit ji'} /> */}
    
      </div>

    <div className="about-para">


      <h1 className="light-heading underline">About Pandit ji</h1>
      <p>Pandit Ji <strong className="strong-heading"> Verander Sharma</strong> is a renowned astrologer who 
        has been practicing astrology, vastu, numerology,
         horoscope analysis, face reading astrology, voice 
         astrology, and dateop beat since 2006. With his vast 
         knowledge and experience in these fields, he has gained
          a significant 
        reputation as a trusted and reliable astrologer.</p>  
        <button className="about-btn">Contact us</button>
        <div className="about-contact-box">
          <div className="contact-box-icon-parent">
            <div className="contact-box-icon"><BiPhoneCall className="contact-box-icons" /></div>
          </div>
                <div className="contact-box-text">
                <h4 className="contact-box-h4">Contact Our Expert Astrologers</h4>
                  <h1 className="contact-box-h1">+91-78569256</h1>
                </div>
        </div>
</div>


</div>  
  

      
   
  );
};

export default About;
