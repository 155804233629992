import "./App.css";
import "./responsive.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Home from "./pages/Home";
// import About from "./pages/About";
import RootLayout from "./Layout/RootLayout";
import HelpLayout from "./Layout/HelpLayout";
import Faq from "./pages/help/Faq";
import Contact from "./pages/help/Contact";
import NotFound from "./pages/NotFound";



import Footer from "./Layout/Footer";
import 'swiper/css';
import AboutLayout from "./Layout/AboutLayout";
import Services from "./pages/Services";
function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<RootLayout />}>
        <Route index element={<Home />} />
        <Route path ='services' element={<Services />} />
        <Route path="about" element={<AboutLayout />} />

        <Route path="contact" element={<Contact />} />
        <Route path="help" element={<HelpLayout />}>
          <Route path="faq" element={<Faq />} />
    
        </Route>

        <Route path="*" element={<NotFound />} />
        
      </Route>
    )
  );
  return (
    <div className="App">
      <div className="whatsapp-btn">

         <a href="//api.whatsapp.com/send?phone=919115950976"> <img className="whatsapp-btn" src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/768px-WhatsApp.svg.png?20220228223904" alt="" /> </a>
      </div>
      {/* <a href="//api.whatsapp.com/send?phone=919115950976"><AiOutlineWhatsApp color="green" className="whatsapp-btn" /> </a> */}
      <RouterProvider router={router} />
      <Footer />
    </div>
  );
}

export default App;
