import React from "react";
import Testmonial from "../Layout/Testmonial";
import HomeForm from "../Layout/HomeForm";
import Cards from "../Layout/Cards";
// import WhyChoose from "../Layout/WhyChoose";
import About from "./About";
// import Horoscope from "../Layout/Horoscope";

const Home = () => {
  return (
    <div className="grid-container">
      <HomeForm />
      <About />
      <Cards />
      {/* <Horoscope /> */}
      {/* <WhyChoose /> */}
       <Testmonial />
       
      </div>


  );
};

export default Home;  

