import React from 'react'
import Cards from '../Layout/Cards'
import WhyChoose from '../Layout/WhyChoose'

const Services = () => {
  return (
    <div className='Services'>
        <Cards />
        <WhyChoose />
    </div>
  )
}

export default Services