import React from 'react'
import About from '../pages/About'
import WhyChoose from './WhyChoose'

const AboutLayout = () => {
  return (
    <div className='AboutLayout'>
        <About />
        <WhyChoose />

    </div>
  )
}

export default AboutLayout