import React from 'react';
import AnimatedImg from "../assets/animated.png";
import { NavLink } from 'react-router-dom';
// import panditji from "../assets/panditji.jpeg";

const HomeForm = () => {
  return (
    <div className='Home'>

  
          <div className='home-heading'>
          <h1 className='heading'>Famous Indian Astrologer,</h1>
          <h1 className="heading">Astrologer Pandit Verander Sharma Ji</h1>          
          <h1 className='heading-h1'> All ASTRO PROBLEM SOLUTION EXPERT </h1>
          <p className='heading-para'>Providing Effective & Assured Soultion for love life,
           Relationship,
          Carrer & Health Related Issues through Astrology.
          </p>
          <NavLink className ='btn' to='contact'>Chat with us !</NavLink>
          {/* <button className='btn' >Chat with us !</button> */}
          </div>
    
        {/* homeheading end here */}

        <div className='home-image'>
        <img src={AnimatedImg} alt="" />
        </div>

    </div>
  )
}

export default HomeForm