import React from 'react'
import chooseImg from "../assets/choose-img.png";

const WhyChoose = () => {
  return (
    <div className='choose-container light-theme'>
        <div className="choose-heading">
   
            <h2 className='light-heading underline'>Why choose us</h2> 
            {/* <p>Lorem ipsum dolor sit amet.</p> */}
        </div>
            <div className="choose-cards">
                    <div className="choose-card">
                        <div className="img-card">
                        <img src={chooseImg} alt="" />
                            <h4 className='h4-img-card'>99+</h4>
                        </div>
                        <p>7+ years of Experience</p>
                    </div>
                    <div className="choose-card">
                    <div className="img-card">
                        <img src={chooseImg} alt="" />
                            <h4 className='h4-img-card'>100+</h4>
                        </div>
                        <p>100+ Happy Customers</p>
                    </div>
                    <div className="choose-card">
                    <div className="img-card">
                        <img src={chooseImg} alt="" />
                            <h4 className='h4-img-card'>512+</h4>
                        </div>
                        <p>WorldWide Users</p>
                    </div>
                    <div className="choose-card">
                    <div className="img-card">
                        <img src={chooseImg} alt="" />
                            <h4 className='h4-img-card'>99.9%</h4>
                        </div>
                        <p>Effective Solutions</p>
                    </div>
                   
                    <div className="choose-card">
                    <div className="img-card">
                        <img src={chooseImg} alt="" />
                            <h4 className='h4-img-card'>100%</h4>
                        </div>
                        <p>Assured Privacy</p>
                    </div>
                    <div className="choose-card">
                    <div className="img-card">
                        <img src={chooseImg} alt="" />
                            <h4 className='h4-img-card'>100%</h4>
                        </div>
                        <p>Safe and Secure</p>
                    </div>
            </div>

        
    </div>
  )
}

export default WhyChoose