import React from "react";
import Animation from "../../assets/animated.png";

const Faq = () => {
  const unhide = () => {
    const faqs = document.querySelectorAll(".faq");
    faqs.forEach((faq) => {
      faq.addEventListener("click", () => {
        faq.classList.toggle("active");
      });
    });
  };

  return (
    <div className="faq-section dark-theme">

      <h3 className="heading underline">Frequently Asked Questions</h3>
    
      <div className="faq-aside home-image">
          <img src = {Animation} />
      </div>

        <div className="faq-aside">


      <div className="faq" onClick={unhide}>
        <div className="question">
          <h3> What is my sun sign, and what does it reveal about my personality ?</h3>
          <svg width={15} height={10} viewBox="0 0 42 25">
            <path
              d="M3 3L21 21L39 3"
              stroke="white"
              stroke-width={"7"}
              stroke-linecap="round"
            />
          </svg>
        </div>
        <div className="answer">
          <p>
          "Your sun sign is determined by your birthdate, and it represents your 
          core identity. It can give insights into your basic character traits, strengths,
            and weaknesses. Let me calculate it for you."

          </p>
        </div>
      </div>

      {/* second */}
      <div className="faq" onClick={unhide}>
        <div className="question">
          <h3>When is the best time for me to start a new business venture ?</h3>
          <svg width={15} height={10} viewBox="0 0 42 25">
            <path
              d="M3 3L21 21L39 3"
              stroke="white"
              stroke-width={"7"}
              stroke-linecap="round"
              />
          </svg>
        </div>
        <div className="answer">
          <p>
          "Starting a business is a significant decision.
           I can look at your astrological indicators for financial success and
            offer guidance on auspicious dates and periods for launching your venture."
          </p>
        </div>
      </div>

      {/* third */}
      <div className="faq" onClick={unhide}>
        <div className="question">
          <h3>Will I find true love this year ?</h3>
          <svg width={15} height={10} viewBox="0 0 42 25">
            <path
              d="M3 3L21 21L39 3"
              stroke="white"
              stroke-width={"7"}
              stroke-linecap="round"
              />
          </svg>
        </div>
        <div className="answer">
          <p>
          "Love is a beautiful aspect of life, and I can analyze your birth chart 
          to see if there are favorable indications for romantic relationships in
           the coming year. Please provide your birth details."

          </p>
        </div>
      </div>
      {/* fourth */}
      <div className="faq" onClick={unhide}>
        <div className="question">
          <h3>How can I improve my career prospects ?</h3>
          <svg width={15} height={10} viewBox="0 0 42 25">
            <path
              d="M3 3L21 21L39 3"
              stroke="white"
              stroke-width={"7"}
              stroke-linecap="round"
            />
          </svg>
        </div>
        <div className="answer">
          <p>
          "Your career is influenced by planetary positions.
           By studying your birth chart and the current transits,
            I can suggest remedies and strategies to enhance your
             professional growth."

          </p>
        </div>
              </div>
      </div>
    </div>
  );
};

export default Faq;
