import React from 'react'
import { CiLocationOn, CiMobile3 } from "react-icons/ci";
import { FaWhatsapp } from 'react-icons/fa';
import { FaInstagram, FaFacebookF,FaSquareTwitter} from "react-icons/fa6";
import logo from ".././assets/logo.png";
const Footer = () => {
 
  return (
    <div className='footer'>
      <div className='footer-container'>
        
    
          {/* 1 block */}
          <div className='footer-block one'>

         <h1 className='footer-underline footer-heading'>Astrologer Pandit ji</h1>
         <div className="footer-img-box">
         <img src={logo} className='footer-logo' alt="logo" />
        <p>Pandit Ji Verander Sharma is a renowned astrologer <br />
         who has been practicing astrology, vastu, numerology, <br /> 
         horoscope analysis, face reading astrology,<br />
           voice astrology, and dateop beat since 2006.!</p>

         </div>
  
        </div>
        {/* 2 block */}
        <div className='footer-block two'>
         <h1 className='footer-underline footer-heading'>Services</h1>
          <li>Astrology</li>
          <li>Vastu</li>
          <li>Numerology</li>
          <li>Hboroscope</li>
          <li>Face Reading Astrologer</li>
          <li>HoroScope Analysis</li>
          <li>Voice Astrologer</li>
          <li>Dateop beat</li>
       
        </div>
        {/* 3 block */}
        <div className='footer-block'>
         <h1  className='footer-underline footer-heading' >Contact us</h1>
          <div className="contact-footer">
          
          <a href="#"><CiLocationOn className='icon-location' /> Mohali, sas Nagar </a> <br />
          <a href="#"><CiMobile3 className='icon-location' />1234567893 </a>
        </div>
          </div>

          {/* 4 block */}
            <div className="footer-block ">
             <h1 className='footer-underline footer-heading'>Follow us</h1>
              <div className="social-links">
                  <a href="#"><FaFacebookF className='social-icon'/></a>
                  <a href="#"><FaInstagram className='social-icon'  /></a>
                  <a href="#"><FaSquareTwitter className='social-icon' /> </a>
                  <a href="#"><FaWhatsapp className='social-icon' /></a>

              </div>
            </div>

           

      </div>
      <div className="footer-block-full">
              <p>Copyright © 2023 Panditji99. All Right Reserved.</p>
            
            </div>

    </div>
  )
}

export default Footer