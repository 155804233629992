import React from "react";
import {BsFillTelephoneInboundFill} from "react-icons/bs";
import { BiLogoGmail} from "react-icons/bi";
const Contact = () => {
  function shareViaWhatsApp() {
      let number =   "+919115950976";
      let message =  document.getElementById("message").value;
      let email =  document.getElementById("email").value;
      let name =  document.getElementById("name").value;
    // const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
      var url = "https://wa.me/" + number + "?text=" 
      + "Name : " +name+ "%0a"
        + "Email :" +email +"%0a" 
        + "Message :" + message + "%0a%0a";
         window.open(url, '_blank').focus();
}
  return (
    <div className="contact-page dark-theme">

          <div className="contact-details ">
              <div className="contact-info">
               <h1 className="underline light-heading">Contact Information</h1>
            <p className="contact-para">Need an immediate solution to your problems?
             If YES!! Pandit <strong className="strong-heading">
              Verander Sharma Ji </strong> is here to help you out of trouble.
              His astrology will take you to the happiness of your life.<br />
             Get in touch with him through the following modes:</p>
              </div>
                      <div className="contact-icons">

                          <div className="contact-icon-box">
                         
                              <div className="contact-icon">
                           <BsFillTelephoneInboundFill className="icon"  />

                              </div>
                           

                            <div className="contact-icon-text">
                                <p>+ (91) 1800-124-105</p>

                            </div>
                          </div>

                             <div className="contact-icon-box mail">

                            
                              <div className="contact-icon">
                              <BiLogoGmail className="icon"/>

                            
                            </div>


                         
                            <div className="contact-icon-text">
                                <p>panditjii99@gmail.com</p>
                               
                            </div>
                          </div>
                      </div>
          </div>
 
        {/* div  end here */}
          <div className="contact-details-form">
        <form>
           <h3 className="form-heading">Have a Question ?</h3>
           <label>
          <span className="">Your Name:</span>
          <input type="text" name="name" id="name" placeholder="Enter your Name" required />
          </label>
          <label>
          <span className="">Your email:</span>
          <input type="email" name="email" id='email' placeholder="Enter your Email" required />
          </label>
    
         <label>

          <span className="white-heading">Your message:</span>
          <textarea name="message" id='message' placeholder="Your Message" required></textarea>

        </label>
        <button className="form-btn" onClick={shareViaWhatsApp}>Submit</button>
        </form> 

            </div>
        </div>

  );
};

export default Contact;
