import React from "react";
import { Outlet, NavLink } from "react-router-dom";
import logo from "../assets/logo.png";
import { TiThMenu } from "react-icons/ti";

const RootLayout = () => {
  return (
    <div className="root-layout">

        <nav>
          <input type="checkbox" id='check'/>
          <label For="check" className="checkbtn"> 
          <TiThMenu/></label>
         
          <label className="logo"><img src={logo} alt="" /></label>
          <ul>
            <li><NavLink className="grid-nav" to="/"> Home</NavLink></li>
            <li> <NavLink className="grid-nav" to="about">About</NavLink></li>
            <li>  <NavLink className="grid-nav" to='services'>Services</NavLink></li>
            <li> <NavLink className="grid-nav" to="help">F&Q</NavLink></li>
            <li>  <NavLink className="grid-nav" to='contact'>Contact</NavLink></li>
          </ul>
        </nav>

  
  

      <main>
        <Outlet />
      </main>
      
    </div>
  );
};

export default RootLayout;
