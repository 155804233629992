import React from 'react'

const Testmonial = () => {
  return (
    <div className='testmonial '>
        <div className="testmonial-heading light-heading ">

        <h3 >Feedback</h3>
        <h1>Client Testmonial</h1>
        </div>
    

        <div className="testmonial-container">

              
      <div className="testmonial-cards">
          <img className="testmonial-card-image" src="https://i.pinimg.com/736x/1d/1f/86/1d1f86b3f727bafba069220a93baa2ad.jpg" alt="" />

        
        <div className="testmonial-text">

      <h1 className='testmonial-card-heading'>Priya Sharma</h1>
      <h6 className='testmonial-card-h6'>Delhi, India</h6>
     <p className='testmonial-card-p'>"I've consulted many astrologers in the past, but none have been as
       accurate and insightful as this astrologer. Their predictions and guidance
        have helped me navigate some of the most challenging times in my life.
       I'm grateful for their wisdom and guidance."</p>
      </div>

        </div>

      <div className="testmonial-cards">
   
         <img className="testmonial-card-image" src="https://media.gettyimages.com/id/1136413215/photo/young-man-at-street-market.jpg?s=612x612&w=gi&k=20&c=XsvT59r4ASMz3tttajBraC9esH7dzZXnBrRdM58ZsW0=" alt="" />

        <div className="testmonial-text">

      <h1 className='testmonial-card-heading'>Rajiv Kapoor</h1>
      <h6 className='testmonial-card-h6'>Chandigarh, India</h6>
     <p className='testmonial-card-p'>"I was skeptical about astrology until I met this astrologer. Their in-depth analysis and precise predictions have left me astonished. Their advice has proven to be invaluable, and I now consult them regularly
       for guidance in both personal and professional matters."</p>
      </div>
   
      </div>

      <div className="testmonial-cards">
          <img className="testmonial-card-image" src="https://wallpaper-house.com/data/out/10/wallpaper2you_368875.jpg" alt="" />

        <div className="testmonial-text">

      <h1 className='testmonial-card-heading'>Sunita Verma</h1>
      <h6 className='testmonial-card-h6'>Mohali, India</h6>
     <p className='testmonial-card-p'> "I'm so thankful to have found this astrologer. Their guidance and predictions
       have been a guiding light in my life. They have helped me understand my strengths and
        weaknesses and provided remedies that have brought peace and prosperity into my life.
       I highly recommend their services to anyone seeking astrological guidance."</p>
      </div>
   
      </div>

        </div>
  

      </div>


  )
}

export default Testmonial
