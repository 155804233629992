import React from "react";
// import { NavLink, Outlet } from "react-router-dom";
import Faq from "../pages/help/Faq";

const HelpLayout = () => {
  return (
    <div className="help-layout">
    
      <Faq />
      {/* <nav>
        <NavLink to="faq">View the FAQ</NavLink>
        <NavLink to="contact">Contact Us</NavLink>
      </nav> */}
      {/* <Outlet /> */}
    </div>
  );
};

export default HelpLayout;
