import React from 'react'
import face from "../../src/assets/face.jpg"

const Cards = () => {
  return (
    <div className='cards light-theme'>
        <div className="card-container">
      <h1 className="center-h1 light-heading services-underline"> Service we Provide</h1> 
     
        <div className="cards dark-theme ">
          
          <div className="card card1">
            <div className="container">
              <img src="https://images.pexels.com/photos/14683265/pexels-photo-14683265.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="" />
            </div>
            <div className="details">
              <h3>Astrology</h3>
              <p>Astrology is the study of celestial bodies and their movements, which is believed
                 to have an impact on human lives and events.</p>
            </div>

          </div>

        {/* second card container */}
        <div className="card card2">
            <div className="container">
              <img src="https://images.pexels.com/photos/6979594/pexels-photo-6979594.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="" />
            </div>
            <div className="details">
              <h3>Vastu</h3>
              <p>Vastu is the ancient Indian science of architecture and construction. It focuses on creating harmonious living and working spaces by aligning the energy flow within a building.</p>
            </div>

        </div>

         {/* third card container */}
         <div className="card card3">
            <div className="container">
              <img src="https://images.pexels.com/photos/11344861/pexels-photo-11344861.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="moon" />
            </div>
            <div className="details">
              <h3>Numerology</h3>
              <p>Numerology is the study of numbers and their influence on
                 human life. Pandit Ji Verander Sharma has expertise in 
                 numerology, </p>
            </div>

        </div>

               {/* fourth card container */}
               <div className="card card3">
            <div className="container">
              <img src="https://images.pexels.com/photos/6806483/pexels-photo-6806483.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="moon" />
            </div>
            <div className="details">
              <h3>Hboroscope</h3>
              <p>Horoscope analysis is a fundamental aspect of astrology.
                 Pandit Ji Verander Sharma delves deep into horoscopes, which 
                 are based on an individual's birth chart,
                 also known as a natal chart.</p>
            </div>

        </div>

               {/* fifth card container */}
               <div className="card card3">
            <div className="container">
              <img src={face} alt="moon" />
            </div>
            <div className="details">
              <h3>Face Reading Astrologer</h3>
              <p>Face reading astrology is another skill that sets Pandit Ji 
                Verander Sharma apart. By analyzing facial features and 
                expressions, he can gain insights into a person's character, 
                behavior patterns, and potential life events</p>
            </div>

        </div>

               {/* sixth card container */}
                <div className="card card3">
            <div className="container">
              <img src="https://www.astrorupaanand.com/admin/assets/images/horoscop/60a11815c9627-1621170197.jpg" alt="moon" />
            </div>
            <div className="details">
              <h3>HoroScope Analysis</h3>
              <p>Horoscope analysis is a fundamental aspect of astrology.
                 Pandit Ji Verander Sharma delves deep into horoscopes,
                  which are based on an individual's birth chart,
                 also known as a natal chart. </p>
            </div>

        </div>

          {/* seventh card container */}
          <div className="card card3">
            <div className="container">
              <img src="https://sanatanastrology.in/wp-content/uploads/2023/03/voice-astrology.jpg" alt="moon" />
            </div>
            <div className="details">
              <h3>Voice Astrologer</h3>
              <p>Voice astrology is a specialized branch of astrology 
                where Pandit Ji Verander Sharma analyzes the tonal quality, 
                pitch, and intonation of a person's voice to gain insights 
                into their personality,
                 emotional state, and future prospects</p>
            </div>

        </div>

          {/* eight card container */}
          <div className="card card3">
            <div className="container">
              <img src="https://images.pexels.com/photos/11344861/pexels-photo-11344861.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="moon" />
            </div>
            <div className="details">
              <h3>Dateop beat </h3>
              <p>Dateop beat is a term that refers to the concept of aligning 
                important events or activities with
                 favorable planetary positions.</p>
            </div>

        </div>


         
        </div>  {/* cards div close here  */}

    </div>

    </div>
  )
}

export default Cards